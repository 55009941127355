<template>
  <div
    ref="preview"
    class="candle-info-preview candle-info-preview--top"
    :style="{ left: `${previewLeft}px`, bottom: `${previewBottom}px` }"
    v-click-outside="blurCandlePreview"
  >
    <div
      v-if="$store.state.global.candlePreview"
      class="candle-info-preview__container"
    >
      <div class="candle-info-preview__columns">
        <div class="candle-info-preview__column">
          <div class="candle-info-preview__row">
            <span class="candle-info-preview__title">Von: </span>
            {{ $store.state.global.candlePreview.info.candle_from }}
          </div>
          <div class="candle-info-preview__row">
            <span class="candle-info-preview__title">Für: </span
            >{{ $store.state.global.candlePreview.info.candle_for }}
          </div>
        </div>
        <div class="candle-info-preview__column">
          <div class="candle-info-preview__date">
            <span class="candle-info-preview__title candle-info-preview__date">
              Bis
              {{
                formattedDate(
                  $store.state.global.candlePreview.info.transaction_time
                )
              }}
            </span>
          </div>
        </div>
      </div>

      <div class="candle-info-preview__message">
        <div class="candle-info-preview__title">Nachricht:</div>
        <div v-html="$store.state.global.candlePreview.info.message"></div>
      </div>

      <div class="candle-info-preview__share">
        <Button @click="sharePage">
          <div class="share-button-container">
            <ShareIcon />
            <span>Teilen</span>
          </div>
        </Button>

        <ShareModal
          v-if="!isMobile"
          v-click-outside="closeShareModal"
          :is-open="shareModalIsOpen"
          position="center"
          :data-for-sharing="{
            title: $store.state.global.candlePreview.info.title,
            url: url,
            text: $store.state.global.candlePreview.info.message,
          }"
          @close="closeShareModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import Button from '@/components/Button/Button.vue';
import ShareModal from '@/components/ShareModal.vue';
import ShareIcon from '@/icons/ShareIcon.vue';

export default {
  name: 'CandlePreview',
  components: { ShareIcon, ShareModal, Button },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      previewIsShown: false,
      previewLeft: 0,
      previewBottom: 0,
      shareModalIsOpen: false,
      url: `${window.location.href}/${this.$store.state.global.candlePreview.info.ID}`,
    };
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
  methods: {
    closeShareModal() {
      this.shareModalIsOpen = false;
    },
    sharePage() {
      if (navigator.share) {
        navigator
          .share({
            title: this.$store.state.global.candlePreview.info.title,
            url: this.url,
            text: this.$store.state.global.candlePreview.info.message,
          })
          .then(() => {
            // console.log('Thanks for sharing!');
          });
        // .catch(console.error);
      } else {
        this.shareModalIsOpen = true;
      }
    },
    formattedDate(date) {
      const d = new Date(date);
      return d.toLocaleDateString('en-GB').replaceAll('/', '.');
    },
    calculatePosition() {
      const previewCoords = this.$refs.preview.getBoundingClientRect();
      const iconCoords = this.$store.state.global.candlePreview.position;

      this.previewLeft =
        iconCoords.left + iconCoords.width / 2 - previewCoords.width / 2;

      // If behind the screen
      if (this.previewLeft + previewCoords.width > window.innerWidth) {
        this.previewLeft = window.innerWidth - previewCoords.width - 20;
      }

      this.previewBottom =
        window.innerHeight - iconCoords.top + iconCoords.height / 2 + 20;
    },
    blurCandlePreview() {
      this.$store.commit('global/setCandlePreview', null);
    },
  },
  watch: {
    '$store.state.global.candlePreview'(val) {
      if (!val) return;
      this.calculatePosition();
    },
  },
  mounted() {
    this.calculatePosition();
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
.candle-info-preview {
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 25px;
  color: #000;
  padding: 20px 25px;
  font-size: 18px;
  line-height: 120%;
  width: 320px;
  text-align: left;

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #fff;
    transform: rotate(45deg);
    left: 50%;
    margin-left: -20px;
    z-index: -1;
    font-size: 18px;
  }

  &--top {
    &:before {
      bottom: -20px;
    }
  }

  &--bottom {
    &:before {
      top: -20px;
    }
  }

  &__columns {
    display: flex;
  }

  &__column {
    &:nth-child(1) {
      flex: 1;
    }
  }

  &__row {
    padding-bottom: 9px;
    border-bottom: 1px solid $mittel_dunkelblau_ci;
    margin-bottom: 9px;
  }

  &__title {
    color: $mittel_dunkelblau_ci;
  }

  &__message {
    .candle-info-preview__title {
      margin-bottom: 9px;
    }
  }

  &__date {
    white-space: nowrap;
  }

  &__share {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: relative;
    &:deep() {
      .button {
        width: max-content;
      }

      .share-button-container {
        display: flex;
        align-items: center;
        span {
          flex: 1;
        }
        svg {
          width: 25px;
          height: 25px;
          transform: translateY(3px);
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
