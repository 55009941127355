<template lang="html">
  <transition name="sidebar-fade">
    <div class="candle-sidebar" v-show="createCandleFormIsShow">
      <div
        v-if="createCandleFormIsShow"
        class="candle-sidebar-background"
        @click="
          $store.commit('donationCandles/setCreateCandleFormIsShow', false)
        "
      ></div>
      <transition name="sidebar-slide">
        <CandleForm v-if="createCandleFormIsShow" />
      </transition>
    </div>
  </transition>

  <div class="candle-carousel">
    <CandlePreview v-if="$store.state.global.candlePreview" />
    <div class="candle-carousel__container">
      <div class="candle-carousel__back">
        <Back
          v-if="globalSettings"
          color="#fff"
          :label="globalSettings['button_names']['back_button']"
          :custom-back="true"
          @back="$router.push('/')"
        />
      </div>
      <Swiper
        :slides-per-view="'auto'"
        :direction="'horizontal'"
        :navigation="true"
        :free-mode="true"
        :loop="false"
        :scrollbar="{
          draggable: true,
          dragSize: 50,
          hide: false,
          snapOnRelease: false,
        }"
        :modules="modules"
      >
        <SwiperSlide v-for="(slide, i) in slides" :key="i">
          <div class="candle-carousel__background" v-if="i === 0"></div>
          <div class="candle-carousel__candles">
            <div
              class="candle-carousel__candle"
              v-for="candle in slide"
              :key="candle.ID"
              ref="slide"
            >
              <!--              v-if="candle.status !== 'create'"-->
              <Candle :info="candle" />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div class="new-candle">
        <Button
          @click.passive="
            $store.commit('donationCandles/setCreateCandleFormIsShow', true)
          "
        >
          <span v-if="globalSettings">
            {{ globalSettings['button_names']['light_button'] }}
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper.css';
import 'swiper/modules/navigation.scss';
import 'swiper/modules/scrollbar.scss';

import { Scrollbar, Navigation, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs';
import Back from '@/components/Back/Back.vue';
import Candle from '@/components/Candle/Candle.vue';
import CandleForm from '@/sections/CandleForm/CandleForm.vue';
import Button from '@/components/Button/Button.vue';
import CandlePreview from '@/components/CandlePreview.vue';

export default {
  name: 'CandleCarousel',
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modules: [Scrollbar, Navigation, FreeMode],
    };
  },
  components: {
    CandlePreview,
    Button,
    Back,
    Candle,
    CandleForm,
    Swiper,
    SwiperSlide,
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    list() {
      return this.$store.state.donationCandles.list;
    },
    createCandleFormIsShow() {
      return this.$store.state.donationCandles.createCandleFormIsShow;
    },
    slides() {
      const formateList = [];
      const sizeArr = 2;

      let listCandles = this.list.filter(
        (candle) => {
          return (
            ['lit'].includes(candle.status) &&
            new Date() <=
              new Date(candle.candle_extinguishing_time.replace(' ', 'T'))
          );
        }
        // 'create'
      );

      // let listCandles = this.list.filter(
      //   (candle) => {
      //     return ['lit'].includes(candle.status);
      //   }
      //   // 'create'
      // );

      if (!listCandles.length) {
        listCandles = this.list.filter((candle) => {
          return ['create'].includes(candle.status);
        });
      }

      const slidesCount = Math.ceil(listCandles.length / 2);
      for (let i = 0; i < slidesCount; i++) {
        formateList[i] = listCandles.slice(i * sizeArr, i * sizeArr + sizeArr);
      }
      return formateList;
    },
  },
  created() {
    this.$store.dispatch('donationCandles/fetchList');
  },
  beforeUnmount() {
    const changeStatusIsShow =
      this.$store.state.donationCandles.changeStatusIsShow;
    if (changeStatusIsShow) {
      this.$store.dispatch('donationCandles/reset');
      this.$store.commit('donationCandles/setChangeStatusIsShow', true);
    } else {
      this.$store.dispatch('donationCandles/reset');
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
