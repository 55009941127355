<template lang="html">
  <div
    class="candle-form"
    :class="{
      'candle-form--preview': donationCandlesStore.previewIsShow,
      'candle-form--checkout': isCheckoutScreen,
    }"
  >
    <!--    <div class="candle-form__candle" v-if="!isMobile">-->
    <!--      <SingleCandle-->
    <!--        typeSection="candle-preview"-->
    <!--        :info="donationCandlesStore.previewData"-->
    <!--        @nextStep="nextStepFromPreview"-->
    <!--        @backStep="backStepFromPreview"-->
    <!--      />-->
    <!--    </div>-->

    <div class="candle-info" v-if="!isMobile && !isCheckoutScreen">
      <div class="candle-info__heading">
        <Heading :h-type="1" text="Kerzenspende" />
      </div>
      <div class="candle-info__description">
        Entzünden Sie eine virtuelle Kerze und teilen Sie eine persönliche
        Botschaft für Ihre Lieben.
      </div>
      <div class="candle-info__number candle-info__title">
        <div>Ihre Kerzennummer</div>
        6320
      </div>
      <div class="candle-info__form-data">
        <div class="candle-info__row">
          <span class="candle-info__label">Von:</span>
          <span>{{ changedData.candle_from }}</span>
        </div>
        <div class="candle-info__row">
          <span class="candle-info__label">Für:</span>
          <span>{{ changedData.candle_for }}</span>
        </div>
        <div class="candle-info__row">
          <span class="candle-info__label">Nachricht:</span>
          <div v-html="changedData.message"></div>
        </div>
      </div>

      <div class="candle-info__bottom-text candle-info__title">
        Brennzeit
        <div>
          {{
            donationCandlesStore.form.currentStep > 1 ? getCandleDuration : '-'
          }}
        </div>
      </div>
    </div>

    <div class="candle-form__form-container">
      <!--      <svg-->
      <!--        v-if="!isMobile"-->
      <!--        class="candle-form__close"-->
      <!--        width="26"-->
      <!--        height="26"-->
      <!--        viewBox="0 0 26 26"-->
      <!--        fill="none"-->
      <!--        xmlns="http://www.w3.org/2000/svg"-->
      <!--        @click="-->
      <!--          $store.commit('donationCandles/setCreateCandleFormIsShow', false)-->
      <!--        "-->
      <!--      >-->
      <!--        <path d="M1 1L25 25" stroke="#0062AE" stroke-width="2" />-->
      <!--        <path d="M25 1L1 25" stroke="#0062AE" stroke-width="2" />-->
      <!--      </svg>-->
      <div
        class="candle-form__steps"
        v-if="!donationCandlesStore.previewIsShow || !isMobile"
      >
        <Steps
          :steps="donationCandlesStore.form.steps"
          :currentStep="donationCandlesStore.form.currentStep"
        />
      </div>
      <CandleFormStep1
        v-if="donationCandlesStore.form.currentStep === 1"
        @change-data="changeData"
      />
      <SingleCandle
        v-if="donationCandlesStore.previewIsShow && isMobile"
        typeSection="candle-preview"
        :info="donationCandlesStore.previewData"
        @nextStep="nextStepFromPreview"
        @backStep="backStepFromPreview"
      />
      <CandleFormStep2
        v-if="
          (donationCandlesStore.form.currentStep === 2 &&
            !donationCandlesStore.previewIsShow) ||
          (donationCandlesStore.form.currentStep === 2 && !isMobile)
        "
      />
      <CandleFormStep3
        v-if="
          (donationCandlesStore.form.currentStep === 3 &&
            !donationCandlesStore.previewIsShow) ||
          (donationCandlesStore.form.currentStep === 3 && !isMobile)
        "
      />
      <CandleFormStep4
        v-if="
          (donationCandlesStore.form.currentStep === 4 &&
            !donationCandlesStore.previewIsShow) ||
          (donationCandlesStore.form.currentStep === 4 && !isMobile)
        "
      />
      <CandleFormStep5 v-if="isCheckoutScreen" />
    </div>
  </div>
</template>

<script>
import Steps from '@/components/Steps/Steps.vue';
import CandleFormStep1 from './CandleFormStep1.vue';
import CandleFormStep2 from './CandleFormStep2.vue';
import CandleFormStep3 from './CandleFormStep3.vue';
import CandleFormStep4 from './CandleFormStep4.vue';
import CandleFormStep5 from './CandleFormStep5.vue';
import SingleCandle from '@/sections/SingleCandle/SingleCandle.vue';
import Heading from '@/components/Heading/Heading.vue';

export default {
  name: 'CandleForm',
  components: {
    Heading,
    Steps,
    CandleFormStep1,
    CandleFormStep2,
    CandleFormStep3,
    CandleFormStep4,
    CandleFormStep5,
    SingleCandle,
  },
  data() {
    return {
      changedData: {},
    };
  },
  computed: {
    isCheckoutScreen() {
      return (
        (this.donationCandlesStore.form.currentStep === 5 &&
          !this.donationCandlesStore.previewIsShow) ||
        (this.donationCandlesStore.form.currentStep === 5 && !this.isMobile)
      );
    },
    donationCandlesStore() {
      return this.$store.state.donationCandles;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
    getCandleDuration() {
      const find = this.$store.state.donationCandles.priceVariants.find(
        ({ id }) => id === this.$store.state.donationCandles.formData.price
      );

      return find && find.duration ? find.duration : '-';
    },
  },
  created() {
    const previewDataLocalStorage = localStorage.getItem('candlePreviewData');

    if (previewDataLocalStorage) {
      const previewDataJson = JSON.parse(previewDataLocalStorage);
      this.$store.commit('donationCandles/setPreviewData', previewDataJson);
      localStorage.removeItem('candlePreviewData');
    }
  },
  methods: {
    changeData(e) {
      this.changedData = e;
    },
    nextStepFromPreview() {
      this.$store.commit('donationCandles/setCurrentStep', 2);
      this.$store.commit('donationCandles/setPreviewIsShow', false);
    },
    backStepFromPreview() {
      this.$store.commit('donationCandles/setCurrentStep', 1);
      this.$store.commit('donationCandles/setPreviewIsShow', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
