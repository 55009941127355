<template lang="html">
  <div class="candle__step-content">
    <FormMessage
      :initData="previewData"
      @submitForm="submitForm"
      @cancelSubmit="cancelSubmit"
      @change-data="changeData"
    />
  </div>
</template>

<script>
import FormMessage from '@/components/Form/FormMessage.vue';

export default {
  name: 'CandleFormStep1',
  components: {
    FormMessage,
  },
  computed: {
    previewData() {
      return this.$store.state.donationCandles.previewData;
    },
  },
  methods: {
    changeData(e) {
      this.$emit('change-data', e);
    },
    submitForm({ formData }) {
      const previewData = {
        candle_from: formData.candle_from,
        candle_for: formData.candle_for,
        message: formData.message,
      };
      this.$store.commit('donationCandles/setPreviewData', previewData);
      this.$store.commit('donationCandles/setPassedStep', 1);
      this.$store.commit('donationCandles/setCurrentStep', 2);
      this.$store.commit('donationCandles/setPreviewIsShow', true);
    },
    cancelSubmit() {
      this.$store.commit('donationCandles/setCreateCandleFormIsShow', false);
      // this.$router.push({
      //   name: 'page',
      //   params: { slug: this.$route.params.slug },
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
