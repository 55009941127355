<template lang="html">
  <div class="candle-form__step-content">
    <FormCheckout @checkoutNextStep="nextStep" />
  </div>
</template>

<script>
import FormCheckout from '@/components/Form/FormCheckout.vue';

export default {
  name: 'CandleFormStep5',
  components: {
    FormCheckout,
  },
  methods: {
    nextStep() {
      this.$store.commit('donationCandles/setChangeStatusIsShow', true);
      this.$nextTick(() => {
        this.$router.push({
          name: 'single-page',
          params: {
            slug: this.$store.state.global.slugs.candles,
            id: this.$store.state.donationPayment.postId,
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>

,
